module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Bitter","file":"https://fonts.googleapis.com/css2?family=Bitter&family=Chakra+Petch&family=Cinzel+Decorative&family=Inria+Serif:wght@400;700&family=Open+Sans:wght@400;500&family=Piazzolla:opsz@8..30&family=Prata&family=Titillium+Web&family=Unna&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
